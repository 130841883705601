



























import { clearAll, clearFacet, clearFilter } from '@/lib/facets';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchFacetDetail, SearchFilterDetail } from '../store/models';

@Component
export default class SearchFacetsComponent extends Vue {
  @Prop() public value: any;

  private autoExpand: number[] = [0];

  private mounted() {
  }

  private changeAllSelected(facet: SearchFacetDetail) {
    facet.facets.map((x) => x.isSelected = false);
    this.$emit('input', this.value);
  }

  private changeSelection(facet: SearchFacetDetail) {
    facet.allSelected = facet.facets.find((x) => x.isSelected === true) === undefined;
    this.$emit('input', this.value);
  }

  private clearAll() {
    clearAll(this.value);
    this.$emit('input', this.value);
  }

  /*
  private clearFacet(facet: SearchFacetDetail) {
    facet.facets.map((x) => x.isSelected = false);
    facet.allSelected = true;
  }

  private clearFilter(filter: SearchFilterDetail) {
    filter.filters.map((x) => x.isSelected = false);
    filter.allSelected = true;
  }
  */
}
