import { EmptyResourceSearchResult, EmptySearchFacetDetail } from '@/store/models-empty';
import { GeneralSearchCriteria, ResourceSearchResult, Aggregate, SearchFacetDetail, SearchFacet, ReferenceData, DiversityDeclarationsMeta, DiversityReferenceData, DiversityRefDataValue, Vendor, Facet, FacetValue, ShortRecord, DiversityRefDataCategory, SearchFilterDetail } from '../store/models';
import { getDisplayNameFromShortRecords, getRefTypeNameFromCode } from '../lib/shared';
import * as _ from 'lodash';

export function setProjectsSearchFacets(criteria: GeneralSearchCriteria, searchResult: ResourceSearchResult|null) {
    const facets: any = searchResult ? searchResult.searchResults.facets : {};
    for (const prop in facets) {
        if (typeof facets[prop] === 'object') {
            const facet: Facet = facets[prop];
            switch (facet.facet) {
                case 'client':
                    setSearchFacet(facet, criteria.facets);
                    break;
                default:
            }
        }
    }
}

export function setPayeeSearchFacets(criteria: GeneralSearchCriteria, searchResult: ResourceSearchResult|null, refData: ReferenceData[], diversityRefData: DiversityReferenceData|null) {
    const facets: any = searchResult ? searchResult.searchResults.facets : {};
    for (const prop in facets) {
        if (typeof facets[prop] === 'object') {
            const facet: Facet = facets[prop];
            switch (facet.facet) {
                case 'proxyPayeeFor':
                    setSearchFacet(facet, criteria.facets);
                    break;
                case 'category':
                    setSearchFacetRefData(facet, criteria.facets, getReferenceDataByType('expenseClass', refData));
                    break;
                case 'indigenousImpact':
                case 'impactESG':
                case 'impactStatus':
                case 'impactSocial':
                case 'socialFocusedOrgs':
                    setDiversitySearchFacet(facet, criteria.facets, diversityRefData);
                    break;
                default:
            }
        }
    }
}

export function setExpenseSearchFacets(criteria: GeneralSearchCriteria, searchResult: ResourceSearchResult|null, refData: ReferenceData[], diversityRefData: DiversityReferenceData|null, shortRecords: ShortRecord[]|null) {
    const facets: any = searchResult ? searchResult.searchResults.facets : {};
    for (const prop in facets) {
        if (typeof facets[prop] === 'object') {
            const facet: Facet = facets[prop];
            switch (facet.facet) {
                case 'expenseType':
                    setSearchFacetRefData(facet, criteria.facets, getReferenceDataByType('expenseTypeAll', refData));
                    break;
                case 'expenseClass':
                    setSearchFacetRefData(facet, criteria.facets, getReferenceDataByType('expenseClass', refData));
                    break;
                case 'project':
                    // setSearchFacetShortRecords(facet, criteria.facets, shortRecords);
                    setSearchFacet(facet, criteria.facets);
                    break;
                case 'indigenousImpact':
                case 'impactESG':
                case 'impactStatus':
                case 'impactSocial':
                case 'socialFocusedOrgs':
                    setDiversitySearchFacet(facet, criteria.facets, diversityRefData);
                    break;
                default:
            }
        }
    }
}

export function getReferenceDataByType(type: string, allRefData: ReferenceData[]): ReferenceData|null {
    const refData = allRefData.find((x) => x.refData.type === type);
    return refData !== undefined ? refData : null;
}

export function getDiversityReferenceDataByCategory(refData: DiversityReferenceData|null, categoryCode: string, categoryValue: string): DiversityRefDataValue[] {
    const topLevelRefData: DiversityRefDataValue[] = refData ?
    (refData as DiversityReferenceData).refData.values.filter((i) => i.categories.find((c) => c.categoryCode === categoryCode)) : [];
    if (topLevelRefData.length > 0) {
        for (const refDataValue of topLevelRefData) {
            for (const refDataValueCat of refDataValue.categories) {
                if (refDataValueCat.categoryCode === categoryCode && refDataValueCat.code === categoryValue) {
                    // Update the name to be the one applicable for this category.
                    refDataValue.name = refDataValueCat.itemNameForCategory;
                }
            }
        }
    }
    return topLevelRefData;
}

export function setDiversitySearchFacet(facet: Facet, facetDetails: SearchFacetDetail[], refData: DiversityReferenceData|null) {
    // Get the existing SearchFacetDetail or create a new one.
    const supplyChainCode = 'supplyChainDiversity';
    let facetDetail: SearchFacetDetail|undefined = facetDetails.find((x) => x.code === supplyChainCode);
    if (facetDetail === undefined) {
        facetDetail = _.cloneDeep(EmptySearchFacetDetail);
        facetDetail.label = 'Supply Chain Diversity';
        facetDetail.code = supplyChainCode;
        facetDetails.push(facetDetail);
    }
    for (const facetValue of facet.values) {
        const refDataByCategory: DiversityRefDataValue[] = getDiversityReferenceDataByCategory(refData, facet.facet, facetValue.value);
        const refDataCategoryItem: DiversityRefDataValue|undefined = refDataByCategory.find((x) => x.categories.find((y) => y.code === facetValue.value) !== undefined);
        const label: string = refDataCategoryItem !== undefined ? refDataCategoryItem.name : facetValue.value as string;
        facetDetail.facets.push({ value: facetValue.value, path: facet.path, label, count: facetValue.count, isSelected: false});
    }
}

export function setSearchFacetRefData(facet: Facet, facetDetails: SearchFacetDetail[], refData: ReferenceData|null) {
    const facetDetail: SearchFacetDetail = _.cloneDeep(EmptySearchFacetDetail);
    facetDetail.label = facet.displayName;
    facetDetail.code = facet.facet;
    for (const facetValue of facet.values) {
        const label: string = refData ? getRefTypeNameFromCode(refData, (facetValue.value as string)) as string : facetValue.value as string;
        facetDetail.facets.push({ value: facetValue.value, path: facet.path, label, count: facetValue.count, isSelected: false});
    }
    facetDetails.push(facetDetail);
}

export function setSearchFacet(facet: Facet, facetDetails: SearchFacetDetail[]) {
    const facetDetail: SearchFacetDetail = _.cloneDeep(EmptySearchFacetDetail);
    facetDetail.label = facet.displayName;
    facetDetail.code = facet.facet;
    for (const facetValue of facet.values) {
        let label: string = facetValue.displayName as string;
        if (label === '') {
            label = 'No ' + facet.displayName;
        } else if (label === undefined) {
            label = facetValue.value;
        }
        facetDetail.facets.push({ value: facetValue.value, path: facet.path, label, count: facetValue.count, isSelected: false});
    }
    facetDetails.push(facetDetail);
}

export function setSearchFacetShortRecords(facet: Facet, facetDetails: SearchFacetDetail[], shortRecords: ShortRecord[]|null) {
    const facetDetail: SearchFacetDetail = _.cloneDeep(EmptySearchFacetDetail);
    facetDetail.label = facet.displayName;
    facetDetail.code = facet.facet;
    for (const facetValue of facet.values) {
        let label: string = shortRecords ? getDisplayNameFromShortRecords(facetValue.value as string, shortRecords) as string : facetValue.value as string;
        if (label === '') {
            label = 'No ' + facet.displayName;
        }
        facetDetail.facets.push({ value: facetValue.value, path: facet.path, label, count: facetValue.count, isSelected: false});
    }
    facetDetails.push(facetDetail);
}

export function isFacetMatch(facetValue: string|string[]|undefined, facets: SearchFacet[]): boolean {
    if (Array.isArray(facetValue)) {
        for (const facetValueItem of facetValue) {
            const facet = facets.find((x) => x.value === facetValueItem && x.isSelected === true);
            if (facet !== undefined) { return true; }
        }
        return false;
    } else {
        const facet = facets.find((x) => x.value === facetValue && x.isSelected === true);
        return facet !== undefined;
    }
}

export function filterPayeeSearchResults(criteria: GeneralSearchCriteria, searchResult: ResourceSearchResult|null): ResourceSearchResult|null {
    if (searchResult) {
        const filteredResult: ResourceSearchResult = _.cloneDeep(EmptyResourceSearchResult);
        for (const result of searchResult.searchResults.results as Vendor[]) {
            let isMatch: boolean = true;
            for (const facet of criteria.facets) {
                if (!facet.allSelected) {
                    // Expense Type facet.
                    if (facet.code === 'expenseType') {
                        // isMatch = isFacetMatch(result.facetDetail.defaultExpenseClass, facet.facets);
                        isMatch = isFacetMatch(result.vendor.defaultExpenseClass, facet.facets);
                    }
                    //Indigenous Community / Partnerships facet.
                    if (facet.label === 'indigenousImpact') {
                        isMatch = false;
                        if (result.diversityDeclarations !== undefined) {
                            if (result.diversityDeclarations.indigenousImpact !== undefined) {
                                isMatch = isFacetMatch(result.diversityDeclarations.indigenousImpact.values, facet.facets);
                            }
                        }
                    }
                    //Third Party Certified Equity Seeking Owned Business facet.
                    if (facet.label === 'impactESG') {
                        isMatch = false;
                        if (result.diversityDeclarations !== undefined) {
                            if (result.diversityDeclarations.impactESG !== undefined) {
                                isMatch = isFacetMatch(result.diversityDeclarations.impactESG.values, facet.facets);
                            }
                        }
                    }
                    //Self-declared Equity Seeking Owned Business facet.
                    if (facet.label === 'socialFocusedOrgs') {
                        isMatch = false;
                        if (result.diversityDeclarations !== undefined) {
                            if (result.diversityDeclarations.socialFocusedOrgs !== undefined) {
                                isMatch = isFacetMatch(result.diversityDeclarations.socialFocusedOrgs.values, facet.facets);
                            }
                        }
                    }
                    //Small Enterprise facet.
                    if (facet.label === 'impactStatus') {
                        isMatch = false;
                        if (result.diversityDeclarations !== undefined) {
                            if (result.diversityDeclarations.impactStatus !== undefined) {
                                isMatch = isFacetMatch(result.diversityDeclarations.impactStatus.values, facet.facets);
                            }
                        }
                    }
                    //Social Purpose Organization facet.
                    if (facet.label === 'impactSocial') {
                        isMatch = false;
                        if (result.diversityDeclarations !== undefined) {
                            if (result.diversityDeclarations.impactSocial !== undefined) {
                                isMatch = isFacetMatch(result.diversityDeclarations.impactSocial.values, facet.facets);
                            }
                        }
                    }
                }
            }
            if (isMatch) {
                (filteredResult.searchResults.results as Vendor[]).push(result);
            }
        }
        return filteredResult;
    } else {
        return null;
    }
}

export function getQsRegionFilter(qsParam: string = 'expense-type'): SearchFilterDetail {
    return { label: 'Region', code: 'expense-region', qsParam, fieldValueParam: '', allSelected: true, filters: [
        { label: 'Forest Heights', values: [ 'Forest Heights'], isSelected: false },
        { label: 'Fulton Place', values: [ 'Fulton Place'], isSelected: false },
        { label: 'Glengarry', values: [ 'Glengarry'], isSelected: false },
        { label: 'Glenora', values: [ 'Glenora'], isSelected: false },
        { label: 'Gold Bar', values: [ 'Gold Bar'], isSelected: false },
        { label: 'Grandview Heights', values: [ 'Grandview Heights'], isSelected: false },
        { label: 'Greenfield', values: [ 'Greenfield'], isSelected: false },
        { label: 'Grovenor', values: [ 'Grovenor'], isSelected: false },
        { label: 'Highlands', values: [ 'Highlands'], isSelected: false },
        { label: 'Holyrood', values: [ 'Holyrood'], isSelected: false },
        { label: 'Idylwylde', values: [ 'Idylwylde'], isSelected: false },
        { label: 'Inglewood', values: [ 'Inglewood'], isSelected: false },
        { label: 'Kenilworth', values: [ 'Kenilworth'], isSelected: false },
        { label: 'Kensington', values: [ 'Kensington'], isSelected: false },
        { label: 'Killarney', values: [ 'Killarney'], isSelected: false },
        { label: 'King Edward Park', values: [ 'King Edward Park'], isSelected: false },
        { label: 'Lansdowne', values: [ 'Lansdowne'], isSelected: false },
        { label: 'Lauderdale', values: [ 'Lauderdale'], isSelected: false },
        { label: 'Laurier Heights', values: [ 'Laurier Heights'], isSelected: false },
        { label: 'Lendrum Place', values: [ 'Lendrum Place'], isSelected: false },
        { label: 'Malmo Plains', values: [ 'Malmo Plains'], isSelected: false },
        { label: 'McQueen', values: [ 'McQueen'], isSelected: false },
        { label: 'Montrose', values: [ 'Montrose'], isSelected: false },
        { label: 'Newton', values: [ 'Newton'], isSelected: false },
        { label: 'North Glenora', values: [ 'North Glenora'], isSelected: false },
    ] };
}

export function getQsExpenseAreaFilter(qsParam: string = 'expense-type'): SearchFilterDetail {
    return { label: 'Expense Area', code: 'expense-area', qsParam, fieldValueParam: '', allSelected: true, filters: [ { label: 'Overhead', values: [ 'general-expense', 'community-contribution', 'donation-expense'], isSelected: false }, { label: 'Project', values: [ 'project-expense', 'project-contribution'], isSelected: false } ] };
}

export function getExpenseAreaFilter(): SearchFilterDetail {
    return { label: 'Expense Area', code: 'expense-area', fieldValueParam: 'expense.expenseType', qsParam: '', allSelected: true, filters: [ { label: 'Overhead', values: [ 'general-expense', 'community-contribution', 'donation-expense'], isSelected: false }, { label: 'Project', values: [ 'project-expense', 'project-contribution'], isSelected: false } ] };
}

export function clearFacet(facet: SearchFacetDetail) {
    facet.facets.map((x) => x.isSelected = false);
    facet.allSelected = true;
}

export function clearFilter(filter: SearchFilterDetail) {
    filter.filters.map((x) => x.isSelected = false);
    filter.allSelected = true;
}

export function clearAll(searchCriteria: GeneralSearchCriteria) {
    const facetDetails = (searchCriteria.facets as SearchFacetDetail[]);
    facetDetails.map((x) => clearFacet(x));
    const filterDetails = (searchCriteria.filters as SearchFilterDetail[]);
    filterDetails.map((x) => clearFilter(x));
    searchCriteria.dateFrom = '';
    searchCriteria.dateTo = '';
}
