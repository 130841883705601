












































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import SearchFiltersComponent from '../components/SearchFiltersComponent.vue';
import SearchFacetsComponent from '../components/SearchFacetsComponent.vue';
import { getIconUrl } from '../lib/images';
import * as colors from '../lib/colors';
import * as datetime from '../lib/datetime';
import { clearAll, getExpenseAreaFilter } from '@/lib/facets';

@Component({
  components: {
    DatePickerComponent,
    SearchFiltersComponent,
    SearchFacetsComponent,
  },
})
export default class FiltersComponent extends Vue {

  @Prop() public value: any;
  @Prop() public bgColor: any;
  @Prop(Boolean) public yearOnly!: boolean;
  @Prop() public minYear!: number | null;

  private colors = colors;
  private searchCriteriaUpdated: number = 0;
  private years = [datetime.getYear()];
  private selectedYear: number | null = this.years[0];

  get showFilters(): boolean {
    // Show filters when not viewing a project
    if (Array.isArray(this.value.projects)) {
      return this.value.projects.length === 0;
    } else {
      return !this.value.projects;
    }
  }

  private mounted() {
    if (this.yearOnly) {
      this.onMinYearChanged();
    }
  }

  @Watch("minYear")
  private onMinYearChanged(): void {
    if (this.minYear) {
      const yearList: number[] = [];
      for (let i = datetime.getYear(); i >= this.minYear; i--) {
        yearList.push(i);
      }
      this.years = yearList;
    }
  }

  private clearAll() {
    clearAll(this.value);
    this.selectedYear = null;
    this.searchCriteriaUpdated++;
    this.$emit('update');
  }

  private updateCharts() {
    this.$emit('update');
  }

  private updateFacetsOrFilters(facets: any) {
  }

  private getFilterIcon(): string {
    return getIconUrl('icon_filter.png');
  }

  private getCardStyle(): string {
    let style: string = this.getStyle();
    style += (';margin-right:' + (this.$vuetify.breakpoint.smAndDown) ? '0px' : '0px');
    return style;
  }

  private getStyle(): string {
    let style = ('background-color: ' + colors.colorLightGrey);
    style += (';color:' + colors.colorDarkBlue);
    return style;
  }

  private setYearFilters(): void {
    this.value.dateFrom = this.selectedYear + '-01-01';
    this.value.dateTo = this.selectedYear + '-12-31';
    this.$emit('update');
  }
}
