
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchFilterDetail } from '../store/models';

@Component
export default class SearchFiltersComponent extends Vue {
  @Prop() public value: any;

  private autoExpand: number[] = [0];

  private mounted() {
  }

  private changeAllSelected(filter: SearchFilterDetail) {
    filter.filters.map((x) => x.isSelected = false);
    this.$emit('input', this.value);
  }

  private changeSelection(filter: SearchFilterDetail) {
    filter.allSelected = filter.filters.find((x) => x.isSelected === true) === undefined;
    this.$emit('input', this.value);
  }
}
