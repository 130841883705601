
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MobileBottomNavComponent extends Vue {

  @Prop() public height: any;

  private isVisible: boolean = false;

  private toggleNav() {
    this.isVisible = !this.isVisible;
  }

  private getStyle(): string {
    return this.height ? ('height:' + (this.height as string) + '%') : '';
  }

  private getClass(): string {
    return this.isVisible ? '' : 'mobile-bottom-nav-hidden ';
  }
}
